<template>
	<div style="height: 100vh;position: relative;">
		<div style="height: 100vh; overflow: auto;">
			<img :src="img" v-for="(img, i) in images" :key="i" style="width: 100%; vertical-align: top;" />
			<div style="height: 200px;"></div>
		</div>
		<div class="btnapply" @click="showPopup = true">免费申请</div>


		<van-popup v-model="showPopup" lock-scroll >
			<div class="addressbox">
				<img :src="'./images/addressbg.jpg'" style="width: 100%; vertical-align: top;" />
				<div class="inpage">
					<div style="padding: 12vw 5vw;">
						<div style="padding-bottom:5px ;">
							<div style="padding-bottom: 4px;">真实姓名:</div>
							<div><input type="text" v-model="formData.realname" class="inputbox" placeholder="所有卡片必须实名认证">
							</div>

						</div>
						<div style="padding-bottom:5px ;">
							<div style="padding-bottom: 4px;">身份证号:</div>
							<div><input type="text" v-model="formData.idcard" class="inputbox" placeholder="请输入开卡人身份证号码">
							</div>

						</div>

						<div style="padding-bottom:5px ;">
							<div style="padding-bottom: 4px;">收货手机号:</div>
							<div><input class="inputbox" v-model="formData.phone" type="number" placeholder="快递接收手机号"></div>

						</div>
						<div style="padding-bottom:5px ;">
							<div style="padding-bottom: 4px;">收货地区:</div>
							<div style="position: relative;">
								<input type="text" :value="formData.province + formData.city + formData.dist"
									class="inputbox" readonly placeholder="请选择收货地区">
								<div class="inpage" @click="showAreaBox">
									<div style="float: right; margin-right: 10px; margin-top: 2px;">
										<van-icon name="arrow-down" />
									</div>
								</div>
							</div>

						</div>
						<div style="padding-bottom:5px ;">
							<div style="padding-bottom: 4px;">详细地址:</div>
							<div><textarea v-model="formData.address" class="inputbox inputarea"
									placeholder="请填写详细收货地址，具体到户号（不可放驿站或代收）"></textarea></div>

						</div>
						<div style="height: 10px; font-size: 10px; color: orangered; line-height: 10px; padding:0 5px 15px 0;">提示：收货时请准备好身份证原件！</div>
						<div class="btnok" @click="saveForm">提交申请</div>



					</div>
				</div>


			</div>

		</van-popup>


		<van-popup v-model="showArea" lock-scroll position="bottom">
			<van-area title="请选择地区" :area-list="areaList" value="410190" @confirm="chooseArea" />

		</van-popup>

	</div>
</template>

<script>
import { Popup, Area, Toast, Dialog } from 'vant';
import { areaList } from '@vant/area-data';
import { Icon } from 'vant';
export default {
	components: { [Popup.name]: Popup, [Area.name]: Area, [Icon.name]: Icon, [Dialog.name]: Dialog },
	data() {
		return {
			cardid: 0,
			who: 0,
			images: [],
			showPopup: false,
			areaList,
			showArea: false,
			formData: {
				taocan_id: '',
				from_id: '',
				phone: '',
				realname: '',
				idcard: '',
				province: '',
				city: '',
				dist: '',
				address: ''
			},
			loading: false

		}
	},
	mounted() {

		this.cardid = this.$Request("g")//goodid
		this.who = this.$Request("f")//fromwhere
		this.getTaocan()


	},
	methods: {
		getTaocan() {
			this.$http.post("/api/get_taocan", { taocan_id: this.cardid }).then(res => {
				this.images = JSON.parse(res.data.files)
			})
		},
		showAreaBox() {
			this.showArea = true
		},
		chooseArea(e) {

			this.showArea = false
			this.formData.province = e[0].name
			this.formData.city = e[1].name
			this.formData.dist = e[2].name
		},
		getAgeFromIdCard(idCard) {
			if (!idCard || idCard.length !== 18) {
				return '请输入有效的身份证号码';
			}

			const birthYear = parseInt(idCard.substr(6, 4), 10);
			const birthMonth = parseInt(idCard.substr(10, 2), 10);
			const birthDay = parseInt(idCard.substr(12, 2), 10);

			const currentDate = new Date();
			const currentYear = currentDate.getFullYear();
			const currentMonth = currentDate.getMonth() + 1;
			const currentDay = currentDate.getDate();

			let age = currentYear - birthYear;
			if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
				age--;
			}

			return age;
		},

		saveForm() {
			this.formData.taocan_id = this.cardid
			this.formData.from_id = this.who

			if (!this.formData.realname) {
				Toast('请输入姓名');
				return
			}
			if (!this.formData.idcard) {
				Toast('请输入身份证号');
				return
			} else {
				const reg = /^(\d{15}$|^\d{18}$|^\d{17}(\d|X|x))$/;
				if (!reg.test(this.formData.idcard)) {
					Toast('身份证号格式错误！');
					return
				}
				let age = this.getAgeFromIdCard(this.formData.idcard)
				if(age<16){
					Toast('16岁以内无法申请！');
					return
				}
			}
			if (!this.formData.phone) {
				Toast('请输入手机号');
				return
			} else {
				const reg = /^1[3-9]\d{9}$/;
				if (!reg.test(this.formData.phone)) {
					Toast('手机号格式错误！');
					return
				}
			}
			if (!this.formData.province || !this.formData.city || !this.formData.dist) {
				Toast('请选择地区');
				return
			}
			if (!this.formData.address) {
				Toast('请输入收货详细地址');
				return
			}
			if (!this.loading) {
				this.loading = true
			} else {
				return
			}
			this.$http.post("/api/apply_card", this.formData).then(res => {

				Dialog.alert({
					message: res.data.msg,
					theme: 'round-button',
				}).then(() => {
					if (res.data.code == 200) {
						this.showPopup = false
					}
					this.loading = false
				});
			})


		}

	}
}
</script>

<style scoped>
.inpage {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
	top: 0;

}

.btnapply {
	width: 50%;
	height: 40px;
	position: absolute;
	bottom: 100px;
	left: 25%;
	background-image: url(../../../public/images/cmcc-apply.png);
	background-size: 100% 100%;
	color: #fff;
	text-align: center;
	line-height: 40px;
}

.addressbox {
	width: 80vw;
	height: 121vw;
	background-size: 100% auto;
	border-radius: 20px;
	position: relative;
	background-color: #fff;
	border-radius: 20px;
	overflow: hidden;
}

.infodt {
	width: 100%;
	font-size: 14px;
}

.inputbox {
	width: 96%;
	border: 1px solid #44b5ff;
	font-size: 14px;
	height: 24px;
}

.inputarea {
	height: 40px;
}

.btnok {
	width: 60%;
	height: 36px;
	background-image: url(../../../public/images/cmcc-apply.png);
	background-size: 100% 100%;
	color: #fff;
	text-align: center;
	line-height: 36px;
	margin: 0 auto;
}
</style>

<style>
.van-popup {
	background-color: rgba(0, 0, 0, 0);
}
</style>